<template>
  <div class="customers">
    <NbPageTitle
      :title="$t('customersPage.title')"
      :subTitle="$t('customersPage.subtitle')"
    >
      <template #subTitle>
        <p class="body-4 mb-0">
          {{ $t("customersPage.subtitle2") }}
        </p>
      </template>

      <div class="d-flex flex-column">
        <h3 class="heading-3">{{ $t("customersPage.newCustomer") }}</h3>
        <NbButton class="mb-1" icon="plus" @click="onCreateCustomer">{{
          $t("customersPage.addCustomer")
        }}</NbButton>
        <NbButton
          icon="arrow-down"
          data-toggle="modal"
          data-target="#modalImportCustomer"
          >{{ $t("components.nav.Import") }}
          {{ $t("components.nav.Customers") }}</NbButton
        >
      </div>
    </NbPageTitle>

    <NbTablev2
      tableOf="customersPage"
      namespace="customers"
      ref="customersTable"
      :allFields="tableFields"
      :fields="fields"
      :vistaBtn="false"
      :tabs="false"
      :selectable="true"
      :optionsWidth="180"
      :buttonOptions="tableButtonOptions"
      @optionTook="optionTook($event)"
      :selectedItems.sync="rowSelection"
      @reloadFields="fields = $event"
      class="table"
    >
      <template #cell(document_number)="data">
        {{ data.item.document_number || "-" }}
      </template>

      <template #cell(city)="data">
        {{ formatAddress(data.item) }}
      </template>

      <template #cell(actions)="data">
        <NbButton
          variant="tertiary"
          icon="edit"
          data-toggle="modal"
          data-target="#modalCustomer"
          @click="setCustomer(data.item)"
        >
          {{ $t("edit") }}
        </NbButton>
        <NbButton
          variant="tertiary"
          icon="trash"
          data-toggle="modal"
          data-target="#modalDeleteCustomer"
          @click="setCustomer(data.item)"
        >
          {{ $t("delete") }}
        </NbButton>
      </template>
    </NbTablev2>

    <ModalImportCustomer @success="refresh" />

    <ModalCustomer
      ref="modalCustomer"
      :customer="customer"
      @success="refresh"
    />

    <ModalDeleteMassiveCustomers
      :ids="rowSelection"
      @success="onDeleteMassive"
    />

    <ModalDeleteCustomer :id="customer?.id" @success="onDelete" />

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle";
import NbButton from "@/components/buttons/NbButton";
import NbTablev2 from "@/components/tables/NbTablev2";
import ModalCustomer from "./components/customers/ModalCustomer.vue";
import ModalDeleteMassiveCustomers from "./components/customers/ModalDeleteMassiveCustomers.vue";
import ModalDeleteCustomer from "./components/customers/ModalDeleteCustomer.vue";
import ModalImportCustomer from "./components/customers/ModalImportCustomer.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import CustomermerService from "@/services/CustomerService";
import moment from "moment";

const customerService = new CustomermerService();

export default {
  name: "Customers",
  components: {
    NbPageTitle,
    NbButton,
    NbTablev2,
    ModalCustomer,
    ModalDeleteMassiveCustomers,
    ModalImportCustomer,
    ModalDeleteCustomer,
    NbFooter,
  },
  data() {
    return {
      fields: [],
      log: [],
      rowSelection: [],
      allFields: [],
      customer: null,
    };
  },
  computed: {
    tableFields() {
      return [
        { key: "full_name", label: this.$t("customer") },
        { key: "email", label: this.$t("email") },
        { key: "phone", label: this.$t("phone") },
        { key: "document_number", label: this.$t("document") },
        { key: "city", label: this.$t("address") },
        { key: "description", label: this.$t("comments") },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
    tableButtonOptions() {
      return [
        {
          text: this.$t("customersPage.deleteCustomer"),
          value: "removeMassiveData",
        },
        {
          text: this.$t("customersPage.downloadCustomers"),
          value: "massiveDownload",
        },
      ];
    },
  },
  methods: {
    refresh() {
      this.$refs?.customersTable?.getData();
    },
    massiveDownload() {
      customerService
        .downloadCustomers(this.rowSelection?.join(","))
        .then((response) => {
          let universalBOM = "\uFEFF";
          let csv =
            "data:text/csv; charset=utf-8," +
            encodeURIComponent(universalBOM + response.data);
          let filename = "customer-" + moment().format("YYYY-MM-DD") + ".csv";
          let anchor = document.createElement("a");
          anchor.href = csv;
          anchor.target = "_blank";
          anchor.download = filename;
          anchor.click();
        })
        .catch((erro) => {
          this.$helpers.toast(
            erro.message || erro.msg || "Request failed",
            "danger"
          );
        });
    },
    removeMassiveData() {
      this.$helpers.openModal("modalDeleteMassiveCustomer");
    },
    optionTook(event) {
      this[event]();
    },
    setCustomer(data) {
      this.$refs.modalCustomer?.resetForm();
      this.customer = data;
    },
    onDeleteMassive() {
      this.rowSelection = [];
      this.refresh();
    },
    onDelete() {
      this.rowSelection = this.rowSelection.filter(
        (item) => item != this.customer?.id
      );
      this.refresh();
    },
    onCreateCustomer() {
      this.customer = {};
      this.$refs.modalCustomer?.resetForm();
      this.$helpers.openModal("modalCustomer");
    },
    formatAddress(item) {
      const address = [`${item.address} ${item.address_number}`];

      if (item.address_complement) {
        address.push(item.address_complement);
      }
      address.push(`${item.postal_code} ${item.city} / ${item.state}`);
      address.push(item.country);

      return address.join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 5rem;
}
</style>
