<template>
  <NbModal id="modalCustomer" modalConfig="modal-dialog-centered modal-lg">
    <template v-slot:header>
      <div>
        <h4 class="heading-4 m-0">
          {{ $t("customersPage.customerData") }}
        </h4>
        <p class="body-4">
          {{
            customer?.id
              ? $t("customersPage.updateCustomer")
              : $t("customersPage.newCustomer")
          }}
        </p>
      </div>
    </template>

    <template v-slot:body>
      <div class="form-wrapper">
        <NbTextInput
          v-model="form.full_name"
          :required="true"
          id="full_name"
          :name="$t('fullName')"
          :placeholder="$t('fullName')"
          :error="validationErrors['full_name']"
          class="fullName"
        />

        <NbTextInput
          v-model="form.email"
          id="email"
          :name="$t('email')"
          :placeholder="$t('email')"
          :error="validationErrors['email']"
          class="email"
        />

        <NbInternationalPhoneInput
          v-model="form.phone"
          id="phone"
          :code="form.phone_number_country"
          @select="setPhoneCountry"
          :name="$t('phone')"
          :placeholder="$t('phone')"
          :error="validationErrors['phone']"
        />

        <NbSelectInput
          v-model="form.country"
          required
          id="country"
          :name="$t('country')"
          :options="countries"
          :placeholder="$t('country')"
          :error="validationErrors['country']"
        />

        <NbTextInput
          v-model="form.postal_code"
          :required="true"
          id="postal_code"
          :name="$t('postalCode')"
          :placeholder="$t('postalCode')"
          :error="validationErrors['postal_code']"
          @blur="findCep(form.postal_code)"
        />

        <NbSelectInput
          v-model="form.state"
          id="state"
          :name="$t('state')"
          :options="states"
          :placeholder="$t('state')"
          :error="validationErrors['state']"
        />

        <NbTextInput
          v-model="form.city"
          :required="true"
          id="city"
          :name="$t('city')"
          :placeholder="$t('city')"
          :error="validationErrors['city']"
        />

        <NbTextInput
          v-model="form.address"
          :required="true"
          id="address"
          :name="$t('address')"
          :placeholder="$t('address')"
          :error="validationErrors['address']"
        />

        <NbTextInput
          v-model="form.address_number"
          id="address_number"
          :name="$t('number')"
          :placeholder="$t('number')"
          :error="validationErrors['number']"
        />

        <NbTextInput
          v-model="form.address_complement"
          id="address_complement"
          :name="$t('complement')"
          :placeholder="$t('complement')"
          :error="validationErrors['complement']"
          class="col-span-3"
        />

        <NbSelectInput
          v-model="form.document_type"
          id="document_type"
          :name="$t('documentType')"
          :options="[
            { text: $t('cpf'), value: 'CPF' },
            { text: $t('cnpj'), value: 'CNPJ' },
          ]"
          :placeholder="$t('documentType')"
          :error="validationErrors['document_type']"
        />

        <NbTextInput
          v-model="form.document_number"
          id="document_number"
          :name="$t('documentNumber')"
          :placeholder="$t('documentNumber')"
          :error="validationErrors['document_number']"
          class="col-span-2"
        />

        <NbTextInput
          v-model="form.description"
          id="description"
          :name="$t('comments')"
          :placeholder="$t('comments')"
          class="col-span-3"
          :error="validationErrors['description']"
        />
      </div>
    </template>

    <template v-slot:footer>
      <div class="d-flex justify-content-between">
        <div>
          <NbButton variant="secondary" data-dismiss="modal" class="mr-2">
            {{ $t("cancel") }}
          </NbButton>
        </div>
        <NbButton data-backdrop="false" @click="submit()" :disabled="loading">
          {{ $t("save") }}
        </NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import CountryService from "@/services/CountryService";
import CustomermerService from "@/services/CustomerService";
import NbInternationalPhoneInput from "@/components/input/phone/NbInternationalPhoneInput.vue";
import AddressAutocompleteService from "@/services/AddressAutocompleteService";

const countryService = new CountryService();
const customerService = new CustomermerService();
const addressAutocompleteService = new AddressAutocompleteService();

const initialForm = {
  full_name: "",
  document_type: "",
  document_number: "",
  phone_number_country: "+55",
  phone: "",
  email: "",
  country: "",
  postal_code: "",
  address: "",
  address_number: "",
  address_quarter: "",
  address_complement: "",
  address_reference: "",
  city: "",
  state: "",
  description: "",
};

export default {
  name: "ModalCustomer",
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbSelectInput,
    NbInternationalPhoneInput,
  },
  props: {
    customer: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: Object.assign({}, initialForm),
      countries: [],
      validationErrors: [],
      statesList: {
        "AC - Acre": "AC",
        "AL - Alagoas": "AL",
        "AP - Amapá": "AP",
        "AM - Amazonas": "AM",
        "BA - Bahia": "BA",
        "CE - Ceará": "CE",
        "DF - Distrito Federal": "DF",
        "ES - Espírito Santo": "ES",
        "GO - Goiás": "GO",
        "MA - Maranhão": "MA",
        "MT - Mato Grosso": "MT",
        "MS - Mato Grosso do Sul": "MS",
        "MG - Minas Gerais": "MG",
        "PA - Pará": "PA",
        "PB - Paraíba": "PB",
        "PR - Paraná": "PR",
        "PE - Pernambuco": "PE",
        "PI - Piauí": "PI",
        "RJ - Rio de Janeiro": "RJ",
        "RN - Rio Grande do Norte": "RN",
        "RS - Rio Grande do Sul": "RS",
        "RO - Rondônia": "RO",
        "RR - Roraima": "RR",
        "SC - Santa Catarina": "SC",
        "SP - São Paulo": "SP",
        "SE - Sergipe": "SE",
        "TO - Tocantins": "TO",
      },
    };
  },
  watch: {
    customer: function (data) {
      if (data?.id) {
        this.setCustomerData();
      }
    },
  },
  computed: {
    states() {
      return Object.entries(this.statesList).map(([text, value]) => ({
        text,
        value,
      }));
    },
  },
  methods: {
    clearCustomerAddressForm() {
      this.form.address = "";
      this.form.address_number = "";
      this.form.address_quarter = "";
      this.form.address_complement = "";
      this.form.address_reference = "";
      this.form.city = "";
      this.form.state = "";
    },
    setCustomerAddressForm(address) {
      this.form.address = address.logradouro;
      this.form.address_quarter = address.bairro;
      this.form.city = address.localidade;
      this.form.state = address.uf;
    },
    async findCep(zip_code) {
      if (this.form.country === "BR") {
        if (zip_code != "") {
          const input_value = /^[0-9]{8}$/;

          if (input_value.test(zip_code)) {
            try {
              const data = await addressAutocompleteService.getCep(zip_code);
              this.setCustomerAddressForm(data);

              if (data.erro) {
                throw new Error("invalid CEP Number");
              }
            } catch (error) {
              this.clearCustomerAddressForm();
              this.$helpers.toast(error.message, "warning");
            }
          }
        }
      } else {
        this.clearCustomerAddressForm();
      }
    },
    setPhoneCountry($event) {
      this.form.phone_number_country = $event?.code;
    },
    getCountries() {
      countryService.getCountries().then((response) => {
        this.countries = response.data.data?.map((country) => ({
          value: country.alpha2_code,
          text: country.name,
        }));
      });
    },
    setCustomerData() {
      this.form = {
        full_name: this.customer.full_name,
        document_type: this.customer.document_type,
        document_number: this.customer.document_number,
        phone_number_country: this.customer.phone_number_country,
        phone: this.customer.phone,
        email: this.customer.email,
        country: this.customer.country,
        postal_code: this.customer.postal_code,
        address: this.customer.address,
        address_number: this.customer.address_number,
        address_quarter: this.customer.address_quarter,
        address_complement: this.customer.address_complement,
        address_reference: this.customer.address_reference,
        city: this.customer.city,
        state: this.customer.state,
        description: this.customer.description,
      };
    },
    resetForm() {
      this.form = initialForm;
      this.validationErrors = [];
    },
    async save() {
      try {
        this.loading = true;
        await customerService.createCustomer(this.form);
        window["modalCustomer-close"]?.click();
        this.resetForm();
        this.$emit("success");
      } catch (error) {
        if (
          error.hasOwnProperty("response") &&
          error.response.hasOwnProperty("data") &&
          error.response.data.hasOwnProperty("messages")
        ) {
          this.validationErrors = error.response.data.messages[0];
        }
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;
        await customerService.updateCustomer(this.customer.id, this.form);
        window["modalCustomer-close"]?.click();
        this.resetForm();
        this.$emit("success");
      } catch (error) {
        if (
          error.hasOwnProperty("response") &&
          error.response.hasOwnProperty("data") &&
          error.response.data.hasOwnProperty("messages")
        ) {
          this.validationErrors = error.response.data.messages[0];
        }
      } finally {
        this.loading = false;
      }
    },
    submit() {
      if (this.customer?.id) {
        this.update();
        return;
      }

      this.save();
    },
  },
  created() {
    this.getCountries();
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 750px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .col-span-3 {
      grid-column: span 3 / span 3;
    }
    .col-span-2 {
      grid-column: span 2 / span 2;
    }
  }
}
</style>
