<template>
  <NbModal id="modalDeleteMassiveCustomer" modalConfig="modal-dialog-centered">
    <template v-slot:header>
      <div>
        <h4 class="heading-4 m-0">
          {{ $t("customersPage.deleteCustomer") }}
        </h4>
        <p class="body-4">
          {{
            $t("confirmDelete", {
              val:
                ids.length > 1
                  ? `${$t("these")} (${ids.length}) ${$tc(
                      "customersPage.found",
                      ids.length
                    )}`
                  : `${$t("this")} (${ids.length}) ${$tc(
                      "customersPage.found",
                      ids.length
                    )}`,
            })
          }}
        </p>
      </div>
    </template>

    <template v-slot:body> </template>

    <template v-slot:footer>
      <div class="d-flex justify-content-between">
        <div>
          <NbButton variant="secondary" data-dismiss="modal" class="mr-2">
            {{ $t("cancel") }}
          </NbButton>
        </div>
        <NbButton data-backdrop="false" @click="submit" :disabled="loading">
          {{ $t("delete") }}
        </NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "../../../../components/modal/NbModal.vue";
import NbButton from "../../../../components/buttons/NbButton.vue";
import CustomermerService from "../../../../services/CustomerService";

const customerService = new CustomermerService();

export default {
  components: {
    NbModal,
    NbButton,
  },
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await customerService.removeMassiveCustomer(this.ids?.join(","));
        this.$helpers.closeModal("modalDeleteMassiveCustomer");
        this.$emit("success");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
