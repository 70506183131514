<template>
  <NbModal id="modalImportCustomer" modalConfig="modal-dialog-centered">
    <template v-slot:header>
      <h4 class="heading-4">
        {{ $t("customersPage.browseFilesToUpload") }}
      </h4>
    </template>

    <template v-slot:body>
      <div class="d-flex flex-column justify-content-center">
        <i class="fas fa-cloud-upload-alt fa-4x mb-1 text-center"></i>
        <p class="text-center">{{ $t("productsPage.selectCsv") }}</p>
        <input
          type="file"
          class="d-none"
          ref="uploadCustomers"
          v-on:change="uploadCustomers"
        />

        <div class="text-center">
          <NbButton
            @click="$refs.uploadCustomers.click()"
            :disabled="loading"
            >{{ $t("upload") }}</NbButton
          >
        </div>

        <p class="text-center mt-1">
          <a href="/customers-import-template.csv" target="_blank">{{
            $t("productsPage.downloadHere")
          }}</a>
          {{ $t("productsPage.csvFile") }}
        </p>
      </div>
    </template>

    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("cancel") }}
        </NbButton>
      </div>
    </template>
  </NbModal>
</template>

<script>
import NbModal from "../../../../components/modal/NbModal.vue";
import NbButton from "../../../../components/buttons/NbButton.vue";
import CustomermerService from "../../../../services/CustomerService";

const customerService = new CustomermerService();

export default {
  components: {
    NbModal,
    NbButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    uploadCustomers(e) {
      let customer = e.target.files[0];
      let fd = new FormData();
      this.$helpers.toast(this.$t("customersPage.importingFile"), "warning");
      fd.append("file", customer);
      this.loading = true;
      customerService
        .uploadCustomers(fd)
        .then(() => {
          this.$helpers.toast(this.$t("customersPage.importedFile"), "success");
          this.$helpers.closeModal("modalImportCustomer");
          this.$emit("success");
        })
        .catch((error) => {
          if (
            error.response.data.exception ==
            "#<ArgumentError: invalid byte sequence in UTF-8>"
          ) {
            this.$helpers.toast(
              "File content invalid characters: &#/%$",
              "danger"
            );
            return false;
          }

          this.$helpers.toast(
            this.$t("customersPage.failedToImportFile"),
            "danger"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
